const Footer = ({ onManageConsent }) => {
  return (
    <div className="flex flex-col items-center w-full mt-10 mb-10">
      <div className="mt-2 md:mt-4 rounded-lg max-w-md w-full text-center ">
        <div className="items-center mt-4">
          <a
            href="https://dmaonline.de/nutzungs-abonnementbedingungen/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-dark hover:text-blue-light text-xs md:text-sm inline mr-3 border-r border-blue-dark pr-2"
          >
            Nutzungsbedingungen
          </a>
          <a
            href="https://dmaonline.de/datenschutzerklaerung-digital-medical-academy-plattform/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-dark hover:text-blue-light text-xs md:text-sm inline mr-3 border-r border-blue-dark pr-2"
          >
            Datenschutz
          </a>
          <a
            href="https://dmaonline.de/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-dark hover:text-blue-light text-xs md:text-sm inline"
          >
            www.dmaonline.de
          </a>
          <button
            className="text-blue-dark hover:text-blue-light text-xs md:text-sm inline  mt-2"
            onClick={onManageConsent}
          >
            Privacy Einstellungen
          </button>
        </div>
      </div>
    </div>
  );
};

export default Footer;